import axios from 'axios'

export const checkIsDisposableEmail = async (email: string) => {
  const isDisposable = await axios({
    url: `https://disposable.debounce.io/?email=${email}`,
    method: 'GET',
    validateStatus(status) {
      return status >= 200 && status < 599
    },
  })

  const isDisposableEmail = JSON.parse(isDisposable.data.disposable)

  return isDisposableEmail
}
