import clientSideAxiosInstance from '@/AxiosConfig'

// interface
import { ApplyCouponPayload } from '@/interface'

class CouponsAPIs {
  async applyCoupon(payload: ApplyCouponPayload) {
    try {
      return await clientSideAxiosInstance.post(`/api/user/coupon/apply`, payload)
    } catch (error: any) {
      return error.response
    }
  }
}

export const couponsApis = new CouponsAPIs()
