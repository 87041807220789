import { profileApi } from '@/APIs'

// Toast
import { MakeToastMessage } from '@/Helpers'

// CONSTANTS
import { DURATION_MEDIUM, SOMETHING_WENT_WRONG } from '@/constants'

// interface
import { GetProfileProps, UpdateProfileFuncProps, verifyEmailOTPUpdateProfileProps } from '@/interface'

export const retrieveProfileDetails = async ({ onSuccess, setOpenModal }: GetProfileProps) => {
  try {
    const response = await profileApi.profileDetails()

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload)
        if (response.data.payload.needEmailVerification) {
          setOpenModal(true)
        }
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const updateProfileDetails = async ({ disableForm, helpers, payload }: UpdateProfileFuncProps) => {
  try {
    const response = await profileApi.updateProfile(payload)

    switch (response.status) {
      case 200:
        disableForm(true)
        break
      case 400:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const verifyEmailOTPUpdateProfile = async ({ payload, onClose }: verifyEmailOTPUpdateProfileProps) => {
  try {
    const response = await profileApi.verifyOTPForUpdateProfile(payload)

    switch (response.status) {
      case 200:
        MakeToastMessage('OTP Verified Successfully!', 'success', DURATION_MEDIUM)
        onClose?.()
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}
