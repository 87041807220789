import toast from 'react-hot-toast'

export const MakeToastMessage = (message: string, type: string, duration: number): void => {
  switch (type) {
    case 'error':
      toast.error(message, { duration })
      break
    case 'success':
      toast.success(message, { duration })
      break
    default:
      break
  }
}
