import axios from 'axios'

// interface
import { LoginProps, RegisterProps, VerifyEmailOTPProps } from '@/interface'

class AuthAPI {
  async login(payload: LoginProps) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/auth/user/signin`,
        method: 'POST',
        data: payload,
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async register(payload: RegisterProps) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/auth/user/signup`,
        method: 'POST',
        data: payload,
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async verifyEmailOTP(payload: VerifyEmailOTPProps) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/auth/user/verify-otp`,
        method: 'POST',
        data: payload,
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }
}

export const authApi = new AuthAPI()
