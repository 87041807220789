import { commonApi } from '@/APIs/CommonAPI'

// Toast
import { MakeToastMessage } from '@/Helpers/MakeToast'

// CONSTANTS
import { DURATION_MEDIUM, SOMETHING_WENT_WRONG } from '@/constants'

// Interface
import {
  GetAllNewlyArrivedProdsApiProps,
  GetAllProductsOfCertainPriceApiProps,
  NewlyArrivedProdsApiProps,
  RetrieveAllCouponsProps,
  retrieveAllProductsOfSubCategProps,
  retrieveApiProps,
  retrieveSubCategApiProps,
  searchProductsApiProps,
} from '@/interface'

export const searchProductsApi = async ({ query, onSuccess, loading }: searchProductsApiProps) => {
  try {
    const response = await commonApi.searchProduct(query)

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }

    loading(false)
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveAllAds = async ({ onSuccess }: retrieveApiProps) => {
  try {
    const response = await commonApi.allAds()

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload.rows)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveSubCategApi = async ({ onSuccess }: retrieveSubCategApiProps) => {
  try {
    const response = await commonApi.categoriesList()

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload.rows)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const NewlyArrivedProdsApi = async ({ onSuccess }: NewlyArrivedProdsApiProps) => {
  try {
    const response = await commonApi.newlyArrivedProds()

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload.rows)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveAllProductsOfSubCateg = async ({ subCategId, pageSize, pageNumber, onSuccess, totalCount }: retrieveAllProductsOfSubCategProps) => {
  try {
    const queryParams = `pageSize=${pageSize}&pageNumber=${pageNumber}`

    const response = await commonApi.allProductsOfSubCateg(subCategId, queryParams)

    switch (response.status) {
      case 200:
        if (pageNumber > 0) {
          onSuccess(response.data.payload.rows)
        } else {
          onSuccess(response.data.payload.rows)
          totalCount(response.data.payload.count)
        }
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveAllNewlyArrivedProducts = async ({ pageSize, pageNumber, onSuccess, totalCount, loading }: GetAllNewlyArrivedProdsApiProps) => {
  try {
    const queryParams = `pageSize=${pageSize}&pageNumber=${pageNumber}`

    const response = await commonApi.getAllNewlyArrivedProds(queryParams)

    switch (response.status) {
      case 200:
        if (pageNumber > 0) {
          onSuccess(response.data.payload.rows)
        } else {
          onSuccess(response.data.payload.rows)
          totalCount(response.data.payload.count)
        }
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }

    loading(false)
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const getAllProductsOfCertainPrice = async ({ priceAmt, isUnderPrice, pageSize, pageNumber, onSuccess, totalCount, loading }: GetAllProductsOfCertainPriceApiProps) => {
  try {
    const queryParams = `priceAmt=${priceAmt}&isPriceUnder=${isUnderPrice}&pageSize=${pageSize}&pageNumber=${pageNumber}`

    const response = await commonApi.getProductsOfCertainPrice(queryParams)

    switch (response.status) {
      case 200:
        if (pageNumber > 0) {
          onSuccess(response.data.payload.rows)
        } else {
          onSuccess(response.data.payload.rows)
          totalCount(response.data.payload.count)
        }
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }

    loading ? loading(false) : null
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveAllCoupons = async ({ onSuccess }: RetrieveAllCouponsProps) => {
  try {
    const response = await commonApi.getAllCoupons()

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload.rows)

        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}
