import clientSideAxiosInstance from '@/AxiosConfig'

// interface
import { UpdateProfilePayloadProps, VerifyEmailOTPProps } from '@/interface'

class ProfileAPI {
  async profileDetails() {
    try {
      return await clientSideAxiosInstance.get(`/api/user/profile/details`)
    } catch (error: any) {
      return error.response
    }
  }

  async updateProfile(payload: UpdateProfilePayloadProps) {
    try {
      return await clientSideAxiosInstance.patch(`/api/user/profile/update`, payload)
    } catch (error: any) {
      return error.response
    }
  }

  async verifyOTPForUpdateProfile(payload: VerifyEmailOTPProps) {
    try {
      return await clientSideAxiosInstance.patch(`/api/user/profile/update/verify-otp`, payload)
    } catch (error: any) {
      return error.response
    }
  }
}

export const profileApi = new ProfileAPI()
