import axios from 'axios'

// Interface
import { SearchProductQueryProps } from '@/interface'

class CommonAPIs {
  async searchProduct(query: SearchProductQueryProps) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/search/product`,
        method: 'POST',
        data: query,
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async allAds() {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/recommendation/ads/all`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async categoriesList() {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/recommendation/sub-category/all`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async getSingleCategory(categId: string) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/recommendation/sub-category/${categId}/detail`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async newlyArrivedProds() {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/recommendation/newly-arrived-products/home/all`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async allProductsOfSubCateg(subCategId: string, queryParams: string) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/products/${subCategId}/all?${queryParams}`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async getAllNewlyArrivedProds(queryParams: string) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/newly-arrived-products/list/all?${queryParams}`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async getProductsOfCertainPrice(queryParams: string) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/recommendation/products/under-certain-price?${queryParams}`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async getSingleProductDetail(productId: string) {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/products/${productId}/details`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }

  async getAllCoupons() {
    try {
      return await axios({
        url: `${process.env.API_URL}/api/common/coupons/all`,
        method: 'GET',
        validateStatus(status) {
          return status >= 200 && status < 599
        },
      })
    } catch (error: any) {
      return error.response
    }
  }
}

export const commonApi = new CommonAPIs()
