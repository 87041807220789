import axios from 'axios'

// localStorage Helpers
import { localStorageData } from '@/Helpers'

const clientSideAxiosInstance = axios.create({
  baseURL: process.env.API_URL,
  validateStatus(status) {
    return status >= 200 && status < 599
  },
})

const isHandlerEnabled = (config: any = {}) => {
  // eslint-disable-next-line no-prototype-builtins
  return !(config.hasOwnProperty('handlerEnabled') && !config.handleEnabled)
}

// request handler
// eslint-disable-next-line consistent-return
const requestHandler = (request: any) => {
  if (isHandlerEnabled(request)) {
    // inject access token here
    const token = localStorageData.getData('userpayload')

    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    }

    return request
  }
}

const errorHandler = (error: any) => {
  if (isHandlerEnabled(error.config)) {
    // Handle errors
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ ...error })
}

const successHandler = (response: any) => {
  if (isHandlerEnabled(response.config)) {
    /* empty */
  }
  return response
}

clientSideAxiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error),
)

clientSideAxiosInstance.interceptors.request.use(request => requestHandler(request))

export default clientSideAxiosInstance
