// jwt-decode
import jwtDecode from 'jwt-decode'
import { localStorageData } from '..'

interface decodedString {
  accountType: string
  userId: string
  email: string
  exp: number
  iat: number
  name: string
  phoneNumber: string | null
}

export const decodedTokenInfo = {
  userName: () => {
    const token: string = localStorageData.getData('userpayload')
    const decoded: decodedString = jwtDecode(token)
    return decoded?.name
  },
  userRegId: () => {
    const token: string = localStorageData.getData('userpayload')
    const decoded: decodedString = jwtDecode(token)
    return decoded?.userId
  },
  userEmail: () => {
    const token: string = localStorageData.getData('userpayload')
    const decoded: decodedString = jwtDecode(token)
    return decoded?.email
  },
}
