export const localStorageData = {
  saveData: (key: string, value: string) => {
    localStorage?.setItem(key, value)
  },
  getData: (key: string) => {
    if (localStorage?.getItem(key)) {
      const data: string | any = localStorage?.getItem(key)
      return data
    }
    return false
  },
  removeData: (key: string) => {
    if (localStorage?.getItem(key)) {
      localStorage?.removeItem(key)
      return true
    }
    return false
  },
  removeAllData: () => {
    localStorage?.clear()
  },
}
