import { couponsApis } from '@/APIs/Coupons'

// Toast
import { MakeToastMessage } from '@/Helpers'

// CONSTANTS
import { DURATION_MEDIUM, SOMETHING_WENT_WRONG } from '@/constants'

// interface
import { RedeemCouponApiProps } from '@/interface'

export const redeemCouponApi = async ({ couponId, isCouponApplied, onClose, onSuccess, payload, setSelectedCoupon }: RedeemCouponApiProps) => {
  try {
    const response = await couponsApis.applyCoupon(payload)

    switch (response.status) {
      case 200:
        setSelectedCoupon(couponId)
        onSuccess(response.data.payload)
        isCouponApplied(true)
        onClose?.()
        break
      case 400:
        setSelectedCoupon('')
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        isCouponApplied(false)
        break
      case 401:
        setSelectedCoupon('')
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        isCouponApplied(false)
        break
      case 403:
        setSelectedCoupon('')
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        isCouponApplied(false)
        break
      case 404:
        setSelectedCoupon('')
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        isCouponApplied(false)
        break
      default:
        setSelectedCoupon('')
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        isCouponApplied(false)
    }
  } catch (error) {
    setSelectedCoupon('')
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    isCouponApplied(false)
  }
}
