// Nextjs
import { NextRouter } from 'next/router'

// API Call
import { createCartEntryApi } from '@/Helpers'

type CartDataProps = {
  pQuantity: number
  perProductPrice: number
  productDetailsFk: string
}

export const addProductToCart = async (router: NextRouter, cartData: CartDataProps, isAuthenticated: boolean) => {
  if (!isAuthenticated) {
    router.push({
      pathname: '/auth/login',
      query: { returnUrl: router.asPath },
    })
  } else {
    await createCartEntryApi({ payload: cartData, router })
  }
}
