import clientSideAxiosInstance from '@/AxiosConfig'

class OrdersAPI {
  async createOrder(payload: string[]) {
    try {
      return await clientSideAxiosInstance.post(`/api/user/order/create`, payload)
    } catch (error: any) {
      return error.response
    }
  }

  async updateAddressForOrder(orderId: string | string[] | undefined, queryParams: string) {
    try {
      return await clientSideAxiosInstance.patch(`/api/user/order/${orderId}/address/update?${queryParams}`)
    } catch (error: any) {
      return error.response
    }
  }

  async allCartBasedOnOrder(orderId: string | string[] | undefined) {
    try {
      return await clientSideAxiosInstance.get(`/api/user/order/${orderId}/carts/all`)
    } catch (error: any) {
      return error.response
    }
  }

  async allPurchasedOrders() {
    try {
      return await clientSideAxiosInstance.get(`/api/user/order/purchased/all`)
    } catch (error: any) {
      return error.response
    }
  }

  async singlePurchasedOrder(orderId: string | string[] | undefined) {
    try {
      return await clientSideAxiosInstance.get(`/api/user/order/purchased/${orderId}/details`)
    } catch (error: any) {
      return error.response
    }
  }
}

export const ordersApi = new OrdersAPI()
