import { cartsApi } from '@/APIs'

// Toast
import { MakeToastMessage } from '@/Helpers/MakeToast'

// CONSTANTS
import { DURATION_MEDIUM, SOMETHING_WENT_WRONG } from '@/constants'

// interface
import { CreateCartEntryApiProps, DeleteAllCartEntryApiProps, DeleteCartEntryApiProps, createCartEntryApiProps } from '@/interface'

export const createCartEntryApi = async ({ payload, router }: createCartEntryApiProps) => {
  try {
    const response = await cartsApi.createCartEntry(payload)

    switch (response.status) {
      case 201:
        MakeToastMessage('Product added to Cart Successfully!', 'success', DURATION_MEDIUM)
        router.push('/cart')
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveAllCartItems = async ({ onSuccess, totalCount, loading }: CreateCartEntryApiProps) => {
  try {
    const response = await cartsApi.getAllCartItems()

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload.rows)
        totalCount(response.data.payload.count)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }

    loading(false)
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}
export const deleteCartItems = async ({ cartId, onSuccess }: DeleteCartEntryApiProps) => {
  try {
    const response = await cartsApi.deleteCart(cartId)

    switch (response.status) {
      case 200:
        onSuccess(prev => prev.filter(id => id.cartDetailsId !== cartId))
        MakeToastMessage('Item removed from the Cart Successfully!', 'success', DURATION_MEDIUM)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const deleteAllCartItems = async ({ payload, onSuccess }: DeleteAllCartEntryApiProps) => {
  try {
    const response = await cartsApi.deleteAllCart(payload)

    switch (response.status) {
      case 200:
        onSuccess([])
        MakeToastMessage('All Items removed from the Cart Successfully!', 'success', DURATION_MEDIUM)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}
