import clientSideAxiosInstance from '@/AxiosConfig'

// interface
import { CreateAddressPayloadValues, UpdateAddressPayloadValues } from '@/interface'

class CustomerAddressesAPI {
  async getAllAddress() {
    try {
      return await clientSideAxiosInstance.get(`/api/user/customer/address/all`)
    } catch (error: any) {
      return error.response
    }
  }

  async createNewAddress(payload: CreateAddressPayloadValues) {
    try {
      return await clientSideAxiosInstance.post(`/api/user/customer/address/create`, payload)
    } catch (error: any) {
      return error.response
    }
  }

  async updateAddress(payload: UpdateAddressPayloadValues, addressId: string) {
    try {
      return await clientSideAxiosInstance.patch(`/api/user/customer/address/${addressId}/update`, payload)
    } catch (error: any) {
      return error.response
    }
  }
}

export const customerAddressesApi = new CustomerAddressesAPI()
