// Nextjs
import { NextRouter } from 'next/router'

import { createOrderFromCart, MakeToastMessage } from '@/Helpers'

// CONSTANTS
import { DURATION_MEDIUM } from '@/constants'

export const ProceedToCheckout = async (selectedCartsId: string[], router: NextRouter) => {
  if (selectedCartsId.length !== 0) {
    await createOrderFromCart(selectedCartsId, router)
  } else {
    MakeToastMessage('Please Select the Product to proceed', 'error', DURATION_MEDIUM)
  }
}
