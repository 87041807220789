import clientSideAxiosInstance from '@/AxiosConfig'

// interface
import { CartRespProps, CreateCartEntryProps } from '@/interface'

class CartsAPI {
  async createCartEntry(payload: CreateCartEntryProps) {
    try {
      return await clientSideAxiosInstance.post(`/api/user/cart/product/create`, payload)
    } catch (error: any) {
      return error.response
    }
  }

  async getAllCartItems() {
    try {
      return await clientSideAxiosInstance.get(`/api/user/cart/all`)
    } catch (error: any) {
      return error.response
    }
  }

  async deleteCart(cartId: string) {
    try {
      return await clientSideAxiosInstance.delete(`/api/user/cart/${cartId}/delete`)
    } catch (error: any) {
      return error.response
    }
  }

  async deleteAllCart(payload: CartRespProps[]) {
    try {
      return await clientSideAxiosInstance.patch(`/api/user/cart/delete/all`, payload)
    } catch (error: any) {
      return error.response
    }
  }
}

export const cartsApi = new CartsAPI()
