// Nextjs
import { NextRouter } from 'next/router'

// API
import { ordersApi } from '@/APIs/Order'

// Toast
import { MakeToastMessage } from '@/Helpers'

// CONSTANTS
import { DURATION_MEDIUM, SOMETHING_WENT_WRONG } from '@/constants'

// interface
import { AddressUpdateForOrderProps, RetrieveAllCartsItemBasedOnOrderProps, RetrieveAllPurchasedOrdersProps, RetrieveSinglePurchasedOrdersProps } from '@/interface'

export const createOrderFromCart = async (payload: string[], router: NextRouter) => {
  try {
    const response = await ordersApi.createOrder(payload)

    switch (response.status) {
      case 201:
        router.push({
          pathname: '/checkout',
          query: { oId: response.data.payload.orderDetailId },
        })
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const addressUpdateForOrder = async ({ orderId, customerAddressId, onSuccess, router }: AddressUpdateForOrderProps) => {
  try {
    const { oId } = router.query

    const queryParams = `customerAddressId=${customerAddressId}`

    const response = await ordersApi.updateAddressForOrder(orderId, queryParams)

    switch (response.status) {
      case 200:
        onSuccess(1)
        router.push({
          pathname: '/checkout',
          query: { oId, currentActivePosition: 1 },
        })
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveAllCartsItemBasedOnOrder = async ({ onSuccess, orderId }: RetrieveAllCartsItemBasedOnOrderProps) => {
  try {
    const response = await ordersApi.allCartBasedOnOrder(orderId)

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveAllPurchasedOrders = async ({ onSuccess, loading }: RetrieveAllPurchasedOrdersProps) => {
  try {
    const response = await ordersApi.allPurchasedOrders()

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload.rows)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }

    loading(false)
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const retrieveSinglePurchasedOrders = async ({ orderId, onSuccess, loading }: RetrieveSinglePurchasedOrdersProps) => {
  try {
    const response = await ordersApi.singlePurchasedOrder(orderId)

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
    }

    loading(false)
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}
