import { customerAddressesApi } from '@/APIs/Addresses'

// Toast
import { MakeToastMessage } from '@/Helpers'

// CONSTANTS
import { DURATION_MEDIUM, SOMETHING_WENT_WRONG } from '@/constants'

// interface
import { createAddressProfileProps, createAddressProps, retrieveAllCustomerAddressProps, updateAddressProps } from '@/interface'

export const retrieveAllCustomerAddress = async ({ onSuccess }: retrieveAllCustomerAddressProps) => {
  try {
    const response = await customerAddressesApi.getAllAddress()

    switch (response.status) {
      case 200:
        onSuccess(response.data.payload.rows)
        break
      case 400:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const createCustomerAddress = async ({ payload, onSuccess, helpers, onClose, fetchAgain }: createAddressProps) => {
  try {
    const response = await customerAddressesApi.createNewAddress(payload)

    switch (response.status) {
      case 201:
        onSuccess(response.data.payload.customerAddressId)
        fetchAgain()
        MakeToastMessage('Address Added Successfully!', 'success', DURATION_MEDIUM)
        onClose?.()
        helpers.resetForm()
        break
      case 400:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const updateCustomerAddress = async ({ addressId, helpers, onClose, payload }: updateAddressProps) => {
  try {
    const response = await customerAddressesApi.updateAddress(payload, addressId)

    switch (response.status) {
      case 200:
        MakeToastMessage('Address Updated Successfully!', 'success', DURATION_MEDIUM)
        onClose?.()
        helpers.resetForm()
        break
      case 400:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const createCustomerAddressProfile = async ({ helpers, onClose, onSuccess, payload }: createAddressProfileProps) => {
  try {
    const response = await customerAddressesApi.createNewAddress(payload)

    switch (response.status) {
      case 201:
        onSuccess(prevValue => [...prevValue, response.data.payload])
        MakeToastMessage('Address Added Successfully!', 'success', DURATION_MEDIUM)
        onClose?.()
        helpers.resetForm()
        break
      case 400:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}
