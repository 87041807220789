import { authApi } from '@/APIs/Auth'

// Toast
import { MakeToastMessage } from '@/Helpers/MakeToast'

// LocalStorage
import { localStorageData } from '@/Helpers/LocalStorage'

// CONSTANTS
import { DURATION_MEDIUM, SOMETHING_WENT_WRONG } from '@/constants'

// interface
import { LoginApiProps, RegisterApiProps, VerifyEmailOTPAPIProps } from '@/interface'

export const loginApi = async ({ payload, router, returnUrl, helpers, changeToAuthenticated }: LoginApiProps) => {
  try {
    const response = await authApi.login(payload)

    switch (response.status) {
      case 200:
        helpers.setSubmitting(false)
        changeToAuthenticated()
        localStorageData.saveData('userpayload', response.data.payload)
        router.push({
          pathname: returnUrl || '/',
        })

        break
      case 400:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const registerApi = async ({ payload, onSuccess, setIsRequired, helpers }: RegisterApiProps) => {
  try {
    const response = await authApi.register(payload)

    switch (response.status) {
      case 201:
        helpers.setSubmitting(false)
        helpers.resetForm()
        setIsRequired(true)
        onSuccess(response.data.payload)
        break
      case 400:
        helpers.setErrors({ submit: response.data.payload })
        setIsRequired(false)
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        helpers.setErrors({ submit: response.data.payload })
        setIsRequired(false)
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        helpers.setErrors({ submit: response.data.payload })
        setIsRequired(false)
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        helpers.setErrors({ submit: response.data.payload })
        setIsRequired(false)
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        helpers.setErrors({ submit: response.data.payload })
        setIsRequired(false)
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        setIsRequired(false)
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    setIsRequired(false)
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}

export const verifyEmailOTPApi = async ({ payload, onSuccess, helpers }: VerifyEmailOTPAPIProps) => {
  try {
    const response = await authApi.verifyEmailOTP(payload)

    switch (response.status) {
      case 200:
        helpers.setSubmitting(false)
        helpers.resetForm()
        onSuccess('Your Account is Verified, please login to continue')
        break
      case 400:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 401:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 403:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 404:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      case 409:
        helpers.setErrors({ submit: response.data.payload })
        MakeToastMessage(response.data.payload, 'error', DURATION_MEDIUM)
        break
      default:
        MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
        break
    }
  } catch (error) {
    MakeToastMessage(SOMETHING_WENT_WRONG, 'error', DURATION_MEDIUM)
  }
}
